<template>
  <div :class="col">
    <input
      :id="id"
      v-model="name"
      :class="{ 'is-invalid': v?.$error }"
      :type="type"
      :maxlength="maxlength"
      class="form-control"
      :autofocus="autofocus"
      autocomplete="off"
      :placeholder="label"
    />
    <label :for="id" class="form-label fw-bolder"
      ><span v-if="v?.required || v?.requiredIf" class="text-danger">*</span
      >{{ label }}</label
    >
    <div v-if="showVisible">
      <a href="#" class="form-text" @click="showPassword"
        ><font-awesome-icon :icon="type == 'text' ? 'eye-slash' : 'eye'" />
        {{ btnText }}</a
      >
    </div>
    <div v-if="v?.$error" class="invalid-feedback">
      {{ msg(v?.$errors[0]) }}
    </div>
    <div v-if="helper" class="form-text" v-text="helper"></div>
  </div>
</template>

<script>
import message from "@/helper/validMsg"
export default {
  props: {
    modelValue: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    col: {
      type: String,
      default: "form-floating",
    },
    label: {
      type: String,
      default: "",
    },
    helper: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "text",
    },
    maxlength: {
      type: Number,
      default: 20,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    showVisible: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue", "update:type"],
  data() {
    return {
      btnText: "顯示內容",
    }
  },
  computed: {
    name: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit("update:modelValue", modelValue)
      },
    },
  },
  methods: {
    msg(s) {
      return message(s)
    },
    showPassword() {
      if (this.type === "password") {
        this.$emit("update:type", "text")
        this.btnText = "隱藏資訊"
      } else {
        this.$emit("update:type", "password")
        this.btnText = "顯示內容"
      }
    },
  },
}
</script>
