<template>
  <div :class="col">
    <select
      :id="name"
      v-model="bindModel"
      :name="name"
      :class="[
        'form-select',
        { 'is-invalid': v?.$error || errObj?.$validator },
      ]"
      :readonly="readonly"
      :disabled="readonly"
    >
      <option v-if="defaultOption" :value="defaultValue">請選擇</option>
      <option v-for="(item, index) in items" :key="index" :value="item.id">
        {{ item.name }}
      </option>
    </select>
    <label :for="name" class="form-label fw-bolder">
      <span v-if="v?.required || v?.requiredIf" class="text-danger">*</span>
      {{ label }}</label
    >
    <div v-if="v?.$error || errObj?.$validator" class="invalid-feedback">
      {{ msg(v?.$errors[0]) }}
    </div>
    <div v-if="helper" class="form-text" v-text="helper"></div>
  </div>
</template>

<script>
import message from "@/helper/validMsg"
export default {
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    col: {
      type: String,
      default: "form-floating",
    },
    label: {
      type: String,
      default: "",
    },
    helper: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
    defaultOption: {
      type: Boolean,
      default: false,
    },
    defaultValue: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      required: false,
      default: Object.assign({}),
    },
    errObj: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue"],
  computed: {
    bindModel: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit("update:modelValue", modelValue)
      },
    },
  },
  methods: {
    msg(s) {
      if (this.errObj?.$validator) {
        return "欄位必填"
      }
      return message(s)
    },
  },
}
</script>
