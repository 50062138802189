import router from "../router"
import AxiosErrCheck from "../api/error.js"
import Swal from "sweetalert2/dist/sweetalert2.js"

const SuccessAlert = msg => {
  Swal.fire({
    title: msg,
    icon: "success",
    showConfirmButton: false,
    timer: 1000,
  })
}
const ErrorAlert = msg => {
  Swal.fire({
    title: "發生錯誤",
    html: msg,
    icon: "error",
  })
}
const WarningAlert = (title, msg, confirmButton) => {
  return Swal.fire({
    title: title,
    text: msg,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#DD6B55",
    confirmButtonText: confirmButton,
    cancelButtonText: "取消",
  })
}
const InfoAlert = (title, msg) => {
  Swal.fire({
    title: title,
    html: msg,
    icon: "info",
  })
}

export const PostDone = () => {
  SuccessAlert("新增成功")
}

export const PutDone = () => {
  SuccessAlert("修改完成")
}

export const DeleteDone = () => {
  SuccessAlert("刪除完成")
}

export const ShowError = (msg, routerName) => {
  ErrorAlert(msg)
  if (routerName) {
    router.push({ name: routerName })
  }
}

export const ShowSuccess = title => {
  SuccessAlert(title)
}

export const ShowInfo = (title, msg) => {
  InfoAlert(title, msg)
}

export const ShowBack = (routerName, isModify) => {
  if (!isModify) {
    router.push({ name: routerName })
    return
  }
  WarningAlert(
    "返回?",
    "您剛剛有異動欄位內容，請問是否離開且不儲存?",
    "確認離開"
  ).then(res => {
    if (res.isConfirmed) {
      router.push({ name: routerName })
    }
  })
}

export const ShowDelete = () => {
  return WarningAlert(
    "是否確認刪除?",
    "請注意，您將無法恢復刪除後的資料",
    "確認刪除"
  )
}

export const ShowAxios = err => {
  let errText = AxiosErrCheck(err)
  ErrorAlert(errText)
}

let MsgAlert = {}
MsgAlert.install = function (app) {
  app.config.globalProperties.$alert = {
    PostDone,
    PutDone,
    DeleteDone,
    Oops: ShowError,
    Success: ShowSuccess,
    Info: ShowInfo,
    Back: ShowBack,
    Delete: ShowDelete,
    Axios: ShowAxios,
  }
}
export default MsgAlert
