import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import MsgAlert from "./helper/alerts.js"

router.beforeEach((to, _from, next) => {
  window.scrollTo(0, 0)
  if (to.meta.title) {
    document.title = to.meta.title + " - " + process.env.VUE_APP_TITLE
  } else {
    document.title = process.env.VUE_APP_TITLE
  }
  next()
})

// fontawesome
import { library } from "@fortawesome/fontawesome-svg-core"
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
library.add(faEye)
library.add(faEyeSlash)

// sweetalert
import VueSweetalert2 from "vue-sweetalert2"
import "sweetalert2/dist/sweetalert2.min.css"

// loading-overlay
import VueLoading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"

createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(VueLoading, { loader: "dots", color: "#87CEFA" })
  .use(MsgAlert)
  .component("font-awesome-icon", FontAwesomeIcon)
  .mount("#app")
