export default function (e) {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
  let scrollLeft =
    document.documentElement.scrollLeft || document.body.scrollLeft
  e.$nextTick(() => {
    const body = document.querySelector("body")
    let domRect = document.querySelector(".is-invalid").getBoundingClientRect()
    body.scrollTo(domRect.left + scrollLeft, domRect.top + scrollTop - 30)
  })
}
