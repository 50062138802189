import { stateMerge } from "vue-object-merge"

export default {
  namespaced: true,
  state: {
    jwt: null,
    refreshToken: null,
    name: null,
  },
  getters: {
    isAuth: state => {
      return Boolean(state.jwt)
    },
    getJWT: state => {
      return state.jwt
    },
    getRefresh: state => {
      return state.refreshToken
    },
  },
  mutations: {
    SET_AUTH(state, data) {
      stateMerge(state, data)
    },
    DELETE_AUTH(state) {
      state.jwt = null
      state.refreshToken = null
      state.name = null
    },
  },
  actions: {
    setAuth(context, options) {
      context.commit("SET_AUTH", options)
    },
    deleteAuth(context) {
      context.commit("DELETE_AUTH")
    },
  },
}
