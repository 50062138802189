const result = {
  ticketNo: "",
  kindName: "",
  controlUserName: "",
  name: "",
  checkDateString: "",
  validDateString: "",
  regulation: "",
  subject: "",
  basis: "",
  ticketDateString: "",
  ticketFile: "",
}

export default result
