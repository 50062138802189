import { createRouter, createWebHistory } from "vue-router"
import HomeView from "../views/HomeView.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "",
      keepAlive: true,
    },
  },
]

const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_BASEFOLDER),
  linkActiveClass: "active",
  routes,
})

export default router
