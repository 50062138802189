<template>
  <div :class="col">
    <label :for="name" class="form-label fw-bolder">
      <span v-if="v?.required || v?.requiredIf" class="text-danger">*</span>
      {{ label }}</label
    >
    <div :class="['input-group', { 'is-invalid': v?.$error }]">
      <input
        :id="name"
        v-model="bindModel"
        :class="['form-control', { 'is-invalid': v?.$error }]"
        :type="type"
        :maxlength="maxlength"
        :name="name"
        :autofocus="autofocus"
        :readonly="readonly"
        :disabled="readonly"
        autocomplete="off"
      />
      <canvas
        id="canvas"
        width="120"
        height="40"
        title="更換驗證碼"
        @click="drawPic"
      ></canvas>
    </div>

    <div v-if="v?.$error" class="invalid-feedback">
      {{ msg(v?.$errors[0]) }}
    </div>
    <div v-if="helper" class="form-text" v-text="helper"></div>
  </div>
</template>

<script>
import message from "@/helper/validMsg"
export default {
  props: {
    modelValue: {
      type: [String, Number],
      required: true,
    },
    validCode: {
      type: String,
      default: "",
    },
    col: {
      type: String,
      default: "col-md-12",
    },
    label: {
      type: String,
      default: "",
    },
    helper: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "number",
    },
    name: {
      type: String,
      default: "",
    },
    maxlength: {
      type: Number,
      default: 20,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      required: false,
      default: Object.assign({}),
    },
  },
  emits: ["update:modelValue", "update:validCode"],
  data() {
    return {
      btnText: "顯示內容",
    }
  },
  computed: {
    bindModel: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit("update:modelValue", modelValue)
      },
    },
  },
  mounted() {
    this.drawPic()
  },
  methods: {
    msg(s) {
      return message(s)
    },
    drawPic() {
      let canvas = document.getElementById("canvas")
      let width = canvas.width
      let height = canvas.height
      let ctx = canvas.getContext("2d")
      ctx.textBaseline = "bottom"
      ctx.fillStyle = this.randomColor(200, 240)
      ctx.fillRect(0, 0, width, height)
      let str = "123456789"
      let validateCode = ""

      for (let i = 0; i < 4; i++) {
        let txt = str[this.randomNum(0, str.length)]
        validateCode += txt
        ctx.fillStyle = this.randomColor(50, 160)
        ctx.font = this.randomNum(30, 40) + "px SimHei"
        let x = 15 + i * 25
        let y = this.randomNum(38, 43)
        let deg = this.randomNum(-20, 20)

        ctx.translate(x, y)
        ctx.rotate((deg * Math.PI) / 180)
        ctx.fillText(txt, 0, 0)
        ctx.rotate((-deg * Math.PI) / 180)
        ctx.translate(-x, -y)
      }

      for (let i = 0; i < 2; i++) {
        ctx.strokeStyle = this.randomColor(40, 180)
        ctx.beginPath()
        ctx.moveTo(this.randomNum(0, width), this.randomNum(0, height))
        ctx.lineTo(this.randomNum(0, width), this.randomNum(0, height))
        ctx.stroke()
      }

      for (let i = 0; i < 50; i++) {
        ctx.fillStyle = this.randomColor(0, 255)
        ctx.beginPath()
        ctx.arc(
          this.randomNum(0, width),
          this.randomNum(0, height),
          1,
          0,
          2 * Math.PI
        )
        ctx.fill()
      }
      this.$emit("update:validCode", validateCode)
    },
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    randomColor(min, max) {
      let r = this.randomNum(min, max)
      let g = this.randomNum(min, max)
      let b = this.randomNum(min, max)
      return "rgb(" + r + "," + g + "," + b + ")"
    },
  },
}
</script>
