<template>
  <h1 class="h3 fw-normal text-center">{{ webName }}</h1>
  <main v-if="!results.ticketNo" class="form-signin">
    <div class="form">
      <select-basic
        v-model="search.kind"
        :v="v$.search.kind"
        :items="options"
        name="kind"
        label="違規單據類型"
      />
      <input-basic
        id="ticketNo"
        v-model="search.ticketNo"
        :v="v$.search.ticketNo"
        :maxlength="v$.search.ticketNo.maxLength.$params.max"
        col="form-floating mt-3"
        label="違規單據號碼"
      />
      <input-basic
        id="identityNo"
        v-model="search.identityNo"
        v-model:type="idInput"
        :v="v$.search.identityNo"
        :maxlength="v$.search.identityNo.maxLength.$params.max"
        col="form-floating mt-3"
        label="管理權人證件號碼"
        :showVisible="true"
      />
      <input-validate
        ref="validInput"
        v-model="search.code"
        v-model:validCode="validCode"
        :v="v$.search.code"
        :maxlength="v$.search.code.maxLength.$params.max"
        name="code"
        label="驗證碼"
        col="form-group mt-3"
        @keyup.enter="clickSubmit"
      />
      <button
        class="w-100 btn btn-lg btn-primary mt-3"
        type="button"
        :disabled="submitProc"
        @click="clickSubmit"
      >
        送出查詢
      </button>
    </div>
  </main>
  <div v-if="results.ticketNo" class="card">
    <div id="tabledesc" class="card-header">違規單據查詢</div>
    <div class="card-body">
      <table
        class="table table-striped table-hover"
        aria-describedby="tabledesc"
      >
        <tr>
          <th class="text-nowrap">違規單據號碼</th>
          <td v-text="results.ticketNo"></td>
        </tr>
        <tr>
          <th class="text-nowrap">違規單據類型</th>
          <td v-text="results.kindName"></td>
        </tr>
        <tr>
          <th class="text-nowrap">管理權人姓名</th>
          <td v-text="results.controlUserName"></td>
        </tr>
        <tr>
          <th class="text-nowrap">場所名稱</th>
          <td v-text="results.name"></td>
        </tr>
        <tr>
          <th class="text-nowrap">檢查時間</th>
          <td v-text="results.checkDateString"></td>
        </tr>
        <tr>
          <th class="text-nowrap">預定複查日期</th>
          <td v-text="results.validDateString"></td>
        </tr>
        <tr>
          <th class="text-nowrap">違反法條</th>
          <td v-text="results.regulation"></td>
        </tr>
        <tr>
          <th class="text-nowrap">主旨</th>
          <td v-text="results.subject"></td>
        </tr>
        <tr>
          <th class="text-nowrap">違反事實及法令依據</th>
          <td style="white-space: pre-line">
            {{ results.basis }}
          </td>
        </tr>
        <tr>
          <th class="text-nowrap">製單日期</th>
          <td v-text="results.ticketDateString"></td>
        </tr>
        <tr>
          <th class="text-nowrap">單據檔案</th>
          <td>
            <a
              class="btn btn-secondary"
              :href="results.ticketFile"
              target="_blank"
              >點此下載</a
            >
          </td>
        </tr>
        <tr>
          <th class="text-nowrap">相關文件</th>
          <td>
            <ul class="list-group">
              <li
                v-for="(download, index) in files"
                :key="index"
                class="list-group-item"
              >
                {{ download.subject }}
                <a
                  v-if="download.pickFile.fileUrl"
                  :href="download.pickFile.fileUrl"
                  class="btn btn-warning"
                  target="_blank"
                  >{{ download.pickFile.fileType }}檔下載</a
                >
                <a
                  v-if="download.odfFile.fileUrl"
                  :href="download.odfFile?.fileUrl"
                  class="btn btn-warning"
                  target="_blank"
                  >{{ download.odfFile?.fileType }}檔下載</a
                >
              </li>
            </ul>
          </td>
        </tr>
      </table>
      <a href="#" class="btn btn-primary" @click="clearObject">重新查詢</a>
    </div>
  </div>
</template>

<script>
import { API } from "@/api/index"
import searchObj from "./obj.search.js"
import resultObj from "./obj.result.js"
import validScroll from "@/helper/validScroll"
import useVuelidate from "@vuelidate/core"
import { required, maxLength, numeric } from "@vuelidate/validators"

import InputBasic from "@/components/inputFloating.vue"
import SelectBasic from "@/components/SelectFloating.vue"
import InputValidate from "@/components/InputValidate.vue"

export default {
  name: "HomeView",
  components: { InputBasic, SelectBasic, InputValidate },
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  data() {
    return {
      webName: process.env.VUE_APP_TITLE,
      submitProc: false,
      idInput: "password",
      validCode: "",
      search: { ...searchObj },
      results: { ...resultObj },
      options: [
        { id: 1, name: "消防安全檢(複)查不合規定限期改善通知單" },
        { id: 2, name: "舉發違反消防法案件及限期改善通知單" },
        { id: 3, name: "逕行舉發違反消防法案件通知單" },
      ],
      files: [],
    }
  },
  mounted() {
    this.search.ticketNo = this.$route.query.number ?? ""
    this.search.kind = this.$route.query.type ?? "1"
  },
  methods: {
    async clickSubmit() {
      this.v$.$touch()
      if (this.v$.$error) {
        validScroll(this)
        return
      }
      // 前端驗證碼
      if (this.validCode != this.search.code) {
        this.$alert.Info("驗證碼錯誤", "")
        this.search.code = ""
        this.$refs.validInput.drawPic()
        return
      }
      // 通過驗證
      this.$refs.validInput.drawPic()
      this.search.code = ""
      this.submitProc = true
      let loader = this.$loading.show()
      await API.Check(this.search)
        .then(response => {
          this.results = response.data.data
          this.files = response.data.downloads
        })
        .catch(err => {
          this.$alert.Axios(err)
        })
      loader.hide()
      this.submitProc = false
    },
    clearObject() {
      this.search = { ...searchObj }
      this.results = { ...resultObj }
      this.files = []
      this.v$.$reset()
    },
  },
  validations() {
    return {
      search: {
        kind: { required, numeric },
        ticketNo: { required, maxLength: maxLength(20) },
        identityNo: { required, maxLength: maxLength(20) },
        code: { required, numeric, maxLength: maxLength(4) },
      },
    }
  },
}
</script>
